const defaultState = {
  needsToAccept: false,
  show: false,
  marketing: false,
  analytics: false,
  personalization: false,
};

const handlers = {
  "COOKIE_COMPLIANCE/SHOW_SIMPLE": (state, action) => ({
    ...state,
    show: "simple",
    needsToAccept: action.needsToAccept || state.needsToAccept,
  }),
  "COOKIE_COMPLIANCE/SHOW_DETAIL": (state, action) => ({
    ...state,
    show: "detail",
    needsToAccept: action.needsToAccept || state.needsToAccept,
  }),
  "COOKIE_COMPLIANCE/HIDE": (state, action) => ({
    ...state,
    show: state.needsToAccept ? "simple" : false,
  }),
  "COOKIE_COMPLIANCE/SET": (state, action) => ({
    ...state,
    marketing: action.marketing,
    analytics: action.analytics,
    personalization: action.personalization,
  }),
  "COOKIE_COMPLIANCE/SAVE": (state, action) => ({
    ...state,
    needsToAccept: false,
  }),
};

export default function reducer(state = defaultState, action) {
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type](state, action);
}
