import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { checkCompliance, saveSettings } from "./cookie_compliance";
import { initialAppLoad } from "./loading";
import complianceFinished from "./analytics";

const defaultSaga = function* (api, router) {
  yield all([
    takeLatest("COOKIE_COMPLIANCE/CHECK_FINISHED", complianceFinished),
    takeLatest("COOKIE_COMPLIANCE/CHECK", checkCompliance, api, router),
    takeLatest("COOKIE_COMPLIANCE/SAVE", saveSettings, api, router),
    takeEvery("INITIALIZE_APP", initialAppLoad, api, router),
  ]);
};

export default defaultSaga;
