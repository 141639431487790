import {combineReducers} from "redux";
import loading from "./loading";
import cookieCompliance from "./cookie_compliance";
import dialog from "./dialog";

const rootReducer = combineReducers({
  loading,
  cookieCompliance,
  dialog,
});

export default rootReducer;
