import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import CookieCompliance from "./components/CookieCompliance";
import {ThemeProvider} from "@material-ui/core/styles";
import {theme} from "./theme";
import "./css/styles.css";
import Dialogs from "./components/Dialogs";
import Cookie from "js-cookie";
import {HIDE_THIRD_PARTY_DIALOG} from "./constants/cookies";

export default function App() {
  const dispatch = useDispatch();

  const showDetails = () => {
    dispatch({
      type: "COOKIE_COMPLIANCE/SHOW_DETAIL",
    });
  };

  useEffect(() => {
    dispatch({
      type: "INITIALIZE_APP",
    });

    const allLinkTags = document.querySelectorAll("a");

    const checkForThirdPartyLink = (url) => {
      const openDialog = (thirdPartyURL) => {
        dispatch({
          type: "DIALOG/SHOW",
          id: "third_party_linking_dialog",
          options: {thirdPartyURL},
        });
      };

      const validateURL = (urlString) => {
        try {
          new URL(urlString);
        } catch (error) {
          return false;
        }
        return true;
      };

      if (!validateURL(url)) return null;

      const urlHref = new URL(url).href;
      const urlHostName = new URL(url).hostname;
      const currentHostName = window.location.hostname;
      const showDialogCookie = Cookie.get(HIDE_THIRD_PARTY_DIALOG);

      if (!showDialogCookie && currentHostName !== urlHostName) {
        openDialog(urlHref);
      } else {
        window.location.href = urlHref;
      }
    };

    allLinkTags.forEach(function (linkTag) {
      linkTag.onclick = (e) => {
        e.preventDefault();
        const getLinkTag = linkTag.href;
        checkForThirdPartyLink(getLinkTag);
      };
    });

    const cookieComplianceButton = document.getElementById(
      "cookie-settings-link"
    );
    if (cookieComplianceButton) {
      cookieComplianceButton.onclick = (e) => {
        e.preventDefault();
        showDetails();
      };
    }
  }, [dispatch, showDetails]);

  return (
    <ThemeProvider theme={theme}>
      <Dialogs />
      <CookieCompliance />
    </ThemeProvider>
  );
}
